export enum PartyRoles {
  PrimaryRepresentative = 'PrimaryRepresentative',
  Assistant = 'Assistant',
}

// @TODO: Update
export enum ClaimRoles {
  Claimant = 'Claimant',
  Respondent = 'Respondent',
  Judge = 'Judge',
  TenantAdmin = 'Tenant Admin',
  SuperAdmin = 'Super Admin',
  ClaimantCustomer = 'Claimant Customer',
}

export enum PartyTypes {
  Business = 'Business',
  Individual = 'Individual',
}

// @TODO: Update
export enum ClaimTypes {
  Monetary = 'Monetary',
  NonMonetary = 'Non Monetary',
  Marketplace = 'Marketplace',
  DeclaratoryRelief = 'Declaratory Relief',
  Employment = 'Employment',
}

export enum JudgeConflictsStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

// @TODO: Update to PascalCase
export enum ClaimFileLocation {
  ClaimMessage = 'claim-message',
  ClaimMessageReply = 'claim-message-reply',
  ClaimDocuments = 'claim-documents',
  OriginalAgreement = 'original-agreement',
  AssignmentAgreement = 'assignment-agreement',
  InitialClaimSubmission = 'initial-claim-submission',
  ClaimantSubmission1 = 'claimant-submission-1',
  RespondentSubmission1 = 'respondent-submission-1',
  ClaimantSubmission2 = 'claimant-submission-2',
  RespondentSubmission2 = 'respondent-submission-2',
  JudgeAward = 'JudgeAward', //@TODO: Camel case is intentional, error on submit award when using snake-case
  CostsAndFees = 'cost-and-fees',
  OrganizationInvoice = 'organization-invoice',
}

/**
 * Claim Statuses
 * This enum needs to be managed on the front end to
 * Because of weird behaviour between deployed and local environments, the keys should be the same as the values, but without the spaces
 */
// @TODO: Update
export enum ClaimStatus {
  Draft = 'Draft',
  PendingReview = 'PendingReview',
  Approved = 'Approved',
  ClaimantService = 'ClaimantService',
  ClaimantJoined = 'ClaimantJoined',
  ClaimantSubmissionInProgress = 'ClaimantSubmissionInProgress',
  ClaimantSubmitted = 'ClaimantSubmitted',
  RespondentService = 'RespondentService',
  RespondentJoined = 'RespondentJoined',
  RespondentSubmissionInProgress = 'RespondentSubmissionInProgress',
  RespondentSubmitted = 'RespondentSubmitted',
  ClaimantResponseInProgress = 'ClaimantResponseInProgress',
  ClaimantResponded = 'ClaimantResponded',
  RespondentResponseInProgress = 'RespondentResponseInProgress',
  RespondentResponded = 'RespondentResponded',
  JudgeAssignmentPending = 'JudgeAssignmentPending',
  JudgeReassignmentPending = 'JudgeReassignmentPending',
  JudgeConflictsPending = 'JudgeConflictsPending',
  JudgeConflictsCleared = 'JudgeConflictsCleared',
  JudgeReviewInProgress = 'JudgeReviewInProgress',
  AwardInProgress = 'AwardInProgress',
  AwardReviewPending = 'AwardReviewPending',
  AwardComplete = 'AwardComplete',
  AwardSignaturePending = 'AwardSignaturePending',
  AwardSignatureComplete = 'AwardSignatureComplete',
  ClaimResolved = 'ClaimResolved',
  ClaimWithdrawn = 'ClaimWithdrawn',
  Paused = 'Paused',
  Submitted = 'Submitted', // @TODO to be removed.
}

// TODO: Update
export enum MethodOfService {
  Electronic = 'Electronic / Email',
  Physical = 'Physical / Mail',
  InPerson = 'In Person',
  NotDefined = 'Service Method Not Defined',
}

// Just front end
export enum LitigantRepresentationTypes {
  Self = 'Self Representation',
  Invite = 'Invite Representation',
  Someone = "Someone's Representative",
}

export enum CostTypes {
  Interest = 'Interest',
  Expense = 'Expense',
  Penalty = 'Penalty',
}

// @TODO: Update
export enum AwardStatus {
  AwardCreated = 'AwardCreated',
  AwardInProgress = 'AwardInProgress',
  AwardInReview = 'AwardInReview',
  SignaturePending = 'SignaturePending',
  SignatureDeclined = 'SignatureDeclined',
  SignatureComplete = 'SignatureComplete',
  SignatureException = 'SignatureException',
  AwardComplete = 'AwardComplete',
}

export enum FileVariation {
  Unsigned = 'Unsigned',
  Signed = 'Signed',
  CorrectedSigned = 'CorrectedSigned',
  CertificateOfCompletion = 'CertificateOfCompletion',
}

export enum ClaimTags {
  TenantInitiated = 'TenantInitiated',
  ClaimantCreated = 'ClaimantCreated',
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export enum UserRoles {
  Claimant = 'Claimant',
  ClaimantCustomer = 'Claimant Customer',
  Respondent = 'Respondent',
  Judge = 'Judge',
  TenantAdmin = 'Tenant Admin',
  Admin = 'Admin',
  SuperAdmin = 'Super Admin',
}

export enum ThemeOptions {
  Light = 'Light',
  Dark = 'Dark',
  Auto = 'Auto',
}

export enum SignatureDocumentType {
  JudgeAward = 'JudgeAward',
}

export enum PhoneTypes {
  Mobile = 'Mobile',
  Landline = 'Landline',
}

export enum MessageStatus {
  Sent = 'Sent',
  Draft = 'Draft',
}

export enum LitigantTypes {
  Business = 'Business',
  Individual = 'Individual',
}

export enum AllowDeny {
  Allow = 'Allow',
  Deny = 'Deny',
}

export enum DocusignEnvelopStatus {
  Draft = 'draft',
  Sent = 'sent',
  Delivered = 'delivered',
  WaitingForOthers = 'Waiting for Others',
  NeedsToSign = 'Needs To Sign.',
  NeedsToView = 'Needs to View',
  Completed = 'completed',
  Correcting = 'correcting',
  Voided = 'voided',
  Declined = 'declined',
  Expired = 'expired',
  DeliveryFailure = 'Delivery Failure',
  AuthenticationFailed = 'Authentication Failed',
  CompletedOrVoidedPurgingSoon = 'Completed or Voided - Purging Soon',
  CompletedOrVoidedPurged = 'Completed or Voided - Purged',
}

export enum SignatureStatus {
  SignaturePending = 'Signature Pending',
  SignatureDeclined = 'Signature Declined',
  SignatureComplete = 'Signature Complete',
}

export enum ClaimSubmissionTypes {
  ClaimantSubmission1 = 'ClaimantSubmission1',
  RespondentSubmission1 = 'RespondentSubmission1',
  ClaimantSubmission2 = 'ClaimantSubmission2',
  RespondentSubmission2 = 'RespondentSubmission2',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ClaimDueDateActions {
  ClaimantSubmissionIsDue = 'ClaimantSubmissionIsDue',
  ClaimantJoinedSubmissionIsDue = 'ClaimantJoinedSubmissionIsDue',
  ClaimantSubmissionInProgressIsDue = 'ClaimantSubmissionInProgressIsDue',

  RespondentSubmissionIsDue = 'RespondentSubmissionIsDue',
  RespondentJoinedSubmissionIsDue = 'RespondentJoinedSubmissionIsDue',
  RespondentSubmissionInProgressIsDue = 'RespondentSubmissionInProgressIsDue',

  ClaimantResponseIsDue = 'ClaimantResponseIsDue',
  ClaimantResponseInProgressIsDue = 'ClaimantResponseInProgressIsDue',

  RespondentResponseIsDue = 'RespondentResponseIsDue',
  RespondentResponseInProgressIsDue = 'RespondentResponseInProgressIsDue',

  JudgeClearConflictIsDue = 'JudgeClearConflictIsDue',

  JudgeAwardIsDue = 'JudgeAwardIsDue',
  JudgeAwardInProgressIsDue = 'JudgeAwardInProgressIsDue',
}

export enum ArbitrationFeePayingParty {
  Claimant = 'Claimant',
  Respondent = 'Respondent',
  Split = 'Split',
}
